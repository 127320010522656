<template>
  <div style="margin-top: 2rem;">
      <h1 id="technologies-header">Technologies</h1>

      <p class="par">I have experience in a wide range of technologies. 
        For example, web development and software development. 
        I am interested and always open for learning new technologies, and I am always looking for new ways to improve myself.
      </p>

      <b-list-group horizontal>
        <b-list-group-item>
           <b-icon icon="laptop"></b-icon><h2>Front-End</h2>
          <p>Experienced with VueJS and Angular</p>
        </b-list-group-item>
        
        <b-list-group-item>
           <b-icon icon="server"></b-icon><h2>Back-End</h2>
          <p>Experienced with Node.JS, ASP.NET, (No)SQL and Java</p>
        </b-list-group-item>
        
        <b-list-group-item>
          <b-icon icon="server"></b-icon><h2>UX/UI</h2>
          <p>Experienced with tools like Figma</p>
        </b-list-group-item>
      </b-list-group>
      
      <hr class="colorLine" align="left">
  </div>  
</template>

<script>
export default {

}
</script>

<style>
.list-group-item {
  background-color: transparent !important;
  border: none !important;
}
</style>