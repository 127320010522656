<template>
  <b-card
    :title="this.title"
    :img-src="this.image"
    :footer="'Stack: ' + this.stack"
    footer-tag="footer"
    style="max-width: 25rem; height: 100%; min-width: 15rem;"
    class="shadow p-2 mb-5 rounded" 
  >

    <b-card-text>{{ this.description }}</b-card-text>

  </b-card>
</template>

<script>
export default {
  name: "Project",
  props: ["title", "description", "stack", "image"]
}
</script>

<style>
.card {
  background-color: #171d23 !important;
}

.card-img {
  height: 40%;
  object-fit: cover;
}
</style>