<template>
  <div style="margin-top: 2rem;">
      <h1 id="projects-header">Projects</h1>

      <!-- Content here -->
      <!-- 
        Maak een Project Component aan
        Project title, info en foto als param
        Bootstrap card component
       -->

      <b-container style="margin: 0;">
        <b-row>
          <b-col col sm="12" md="6" lg="4" v-for="project in this.projects" :key="project">
            <Project 
              :title="project.title"
              :description="project.description"
              :image="project.image"
              :stack="project.stack"
            />
          </b-col>
        </b-row>
      </b-container>

      <hr id="line" class="colorLine" align="left">
  </div>
</template>

<script>
import Project from './Project';

export default {
  components: {
    Project
  },
  data() {
    return {
      projects: [
        {
          title: "Staff Association Input",
          description: "This application was made for McDonald's Alblasserdam. This application allows for crew to post their input, such as focussing on procedures that are not properly adhered to. This application is provided with a dashboard and mailing system so colleagues in the Staff Association team can review input.",  
          stack: "VueJS, Node.JS (Express), MongoDB",
          image: "/images/mcd1125.png",
        },
        {
          title: "CarpeDiem Paint",
          description: "This application was made for CarpeDiemShipping. This application is made to both make orders for paint, and keep track of inventory shared across ships and other places.",
          stack: "Angular, Node.JS (Express), MongoDB",
          image: "/images/ManuForti.jpg"
        },
        {
          title: "iHomer Ratings",
          description: "This application was made for iHomer. The goal of this application is to provide a platform for users to give ratings or feedback to other users.",
          stack: "VueJS, Node.JS (Express), MongoDB",
          image: "/images/iHomer.png"
        }
      ]
    }
  }
}
</script>

<style>
#projects-header {
  margin-bottom: 3rem;
}

#line {
  margin-top: 4rem;
}
</style>