<template>
  <div style="margin-top: 2rem; margin-bottom: 2rem;">
      <h1 id="about-header">About</h1>

      <p class="par">
        I am Stan Barkmeijer, student of computer science at Avans University of Applied Sciences.
        In addition to my studies, I have been a management trainee at McDonald's Alblasserdam since October, where I have been working for almost 4 years now, I also live in this village.
        Lately I've been making videos on YouTube for viewers who want to get started with programming or expand their knowledge.
        I have been programming for about {{ yearDifference() }} years now, and have not only gained my own knowledge through these videos
        share, but also expand.
        Recently I had the opportunity to do some freelance assignments for carpediemshipping and mcdonald's.
        In my spare time I do a lot of sports, both strength training and cardio, because as a programmer you sit too much.
        A big dream of mine is to run the marathon.
      </p>
  </div>    
</template>

<script>
export default {
  methods: {
    yearDifference: new Date().getFullYear() - 2016,
  }
}
</script>

<style>

</style>