<template>
  <div id="app">
    <Nav />
    <div id="contentBox">
      <Intro />
      <Projects />
      <Technologies />
      <About />
      <Footer />
    </div>
  </div>
</template>

<script>
import Nav from './components/Nav';

import Intro from './components/Intro';
import Projects from './components/Projects';
import Technologies from './components/Technologies';
import About from './components/About';

import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Nav,
    Intro,
    Projects,
    Technologies,
    About,
    Footer
  }
}
</script>

<style>
    html {
      background-color: #1a2129;
      scroll-behavior: smooth;
    }

    #app {
      font-family: "Roboto" sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: white;
      background-color: #1a2129;
    }

    h1 {
      font-family: "Roboto", sans-serif;
      font-size: 70px !important;
      font-weight: bold !important;
      
      background: rgb(0, 204, 255);
      background: linear-gradient(90deg, rgb(0, 204, 255) 0%, rgb(193, 117, 255) 35%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h2 {
      font-family: "Roboto" sans-serif;
    }

    p {
      font-family: "Montserrat";
    }

    .colorLine {
        width: 100px;
        height: 2px;
        margin-top: 2rem;

        border-radius: 10px;
        background: linear-gradient(90deg, rgb(0, 204, 255) 0%, rgb(193, 117, 255) 100%);
    }

    #contentBox {
      margin-left: 20rem;
      margin-right: 15rem;
      margin-top: 3rem;
    }

    @media only screen and (max-width: 1200px) {
        h1 {
          font-size: 35px !important;

          background: rgb(0, 204, 255);
          background: linear-gradient(90deg, rgb(0, 204, 255) 0%, rgb(193, 117, 255) 45%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        h2 {
          font-size: 25px !important;
        }

        p {
          font-size: 15px !important;
        }

        #contentBox {
          margin-left: 2rem;
          margin-right: 2rem;
        }
    }
</style>
