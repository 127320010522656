<template>
    <div>
        <h1 id="intro-header"><span id="sur">Stan</span> Barkmeijer</h1>
        <p class="par">
            My name is Stan Barkmeijer, I'm currently {{ difference() }} years old, and studying at <a href="https://avans.nl/" target="#blank">Hogeschool Avans</a>.

            I'm a software engineer specialized in building and contributing in  exceptional back-end projects. 
            I have a passion for learning and I'm always looking for new ways to improve myself.
        </p>
       <hr class="colorLine" align="left">
    </div>
</template>

<script>
    export default {
        data() {
            return {
                birthday: new Date("July 16, 2001"),
                today: new Date()
            }
        },
        methods: {
            difference() {
                let diff = (this.birthday - this.today) / 1000;
                diff /= (60 * 60 * 24);

                return Math.abs(Math.round(diff / 365.25));
            }
        }
    }
</script>

<style>
    /* #sur {
      background: white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    } */

    #about {
        max-width: 60%;
        margin-top: 1rem;

        font-size: 20px;
    }

    @media only screen and (max-width: 700px) {
        .par {
            max-width: 90%;
        }
    }
</style>