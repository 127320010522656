<template>
    <div class="footer">
        <hr id="footer-line">
        
        <footer style="margin-top: 50px;">
            <b-container>
                <b-row>
                    <b-col>
                        <h2 class="footer-head">Phone</h2>
                        <p><a class="nav-link" id="a" href="tel:0648162297">06 48162297</a></p>
                    </b-col>
                    <b-col>
                        <h2 class="footer-head">E-Mail</h2>
                        <p><a class="nav-link" id="a" href="mailto:stan@barkmeijer.net">stan@barkmeijer.net</a></p>
                    </b-col>
                    <b-col>
                        <h2 class="footer-head">Links</h2>
                        <ul style="list-style-type: none; padding-left: 0">
                            <li> <a class="nav-link" id="a" href="https://github.com/StanBarkmeijer"><b-icon icon="github"></b-icon> Github</a></li>
                            <li> <a class="nav-link" id="a" href="https://gitlab.com/StanBarkmeiejr"><b-icon icon="hdd-network"></b-icon> Gitlab</a></li>
                            <li> <a class="nav-link" id="a" href="https://www.linkedin.com/in/stan-barkmeijer-2001/"><b-icon icon="linkedin"></b-icon> Linkedin</a></li>
                        </ul>
                    </b-col>
                </b-row>
            </b-container>
        </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.footer {
    margin-bottom: 50px;
}

#footer-line {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.footer-head {
    color: rgba(255, 255, 255, 0.5);
    font-size: 20px;
}

#a {
    margin-left: 0px;
    padding-left: 0px;
    color: rgba(255, 255, 255, 0.5);
}

#a:hover {
    color: white;
}
</style>